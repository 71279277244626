import { PRODUCT_SET_RECEIVED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'sana/events';

const initialState = {
    items: {},
};
 /* istanbul ignore next */
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PRODUCT_SET_RECEIVED:
            const { contentBlockId, products } = payload;
            return {
                ...state,
                items: {
                    ...state.items,
                    [contentBlockId]: products,
                },
            };
        case VIEWER_CHANGED:
            return onViewerChanged(state);
        case LANGUAGE_CHANGED:
            /* istanbul ignore next */
            return onLanguageChanged(state);
        default:
            return state;
    }
    /* istanbul ignore next */
    function onViewerChanged(state) {
        return { ...state, items: undefined };
    }
    /* istanbul ignore next */
    function onLanguageChanged(state) {
        if (!state.items)
            return state;

        return {
            ...state,
            items: Object.entries(state.items).reduce((items, [key, value]) => {
                items[key] = { ...value, expired: true };
                return items;
            }, {}),
        };
    }
};

export default reducer;